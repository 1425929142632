import 'owl.carousel';

import PhotoSwipe from 'photoswipe'
import fullscreen from 'screenfull'

$(function () {
    // создание карусели больших изображений
    const jqCarouselPreview = $('.psv0-carousel-preview')
    let carouselPreviewIndex = -1

    jqCarouselPreview
        .owlCarousel({
            items: 1,
            loop: true,
            nav: true,
            navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
            dots: false
        })

    // создание карусели маленьких изображений
    const jqCarouselThumbnails = $('.psv0-carousel-thumbnails')

    jqCarouselThumbnails
        .owlCarousel({
            items: 4,
            loop: jqCarouselThumbnails.find('.item').length > 4,
            margin: 0,
            nav: true,
            navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
            dots: false
        })

    // функция синхронизации двух галерей
    const linkIndex = function (newIndex) {
        // индексы видимых маленьких изображений
        const thumbnailsIndexes = jqCarouselThumbnails
            .find('.owl-item.active .item')
            .map((i, e) => $(e).data('index'))
            .get()

        // синхронизация большого изображения с набором из маленьких (в случае если большого нет в маленьких)
        if (!thumbnailsIndexes.includes(newIndex)) {
            const directionIsNext = (newIndex === (carouselPreviewIndex + 1)) ||
                ((newIndex === 0) && (newIndex !== (carouselPreviewIndex - 1)))
            jqCarouselThumbnails.trigger(directionIsNext ? 'next.owl.carousel' : 'prev.owl.carousel', [300])
        }

        carouselPreviewIndex = newIndex

        // title
        $('.psv0-title').html(
            jqCarouselPreview
                .find('.owl-item:not(.cloned) .item[data-index=' + carouselPreviewIndex + ']')
                .attr('data-title') || ''
        )
        $('.psv0-title-index').html(carouselPreviewIndex + 1)

        // выделение маленького изображения
        jqCarouselThumbnails
            .find('.owl-item.active .item[data-index=' + carouselPreviewIndex + ']')
            .trigger('mouseenter')
    }

    // обработчик смены большого изображения
    jqCarouselPreview.on('changed.owl.carousel', function (e) {
        const newIndex = (e && e.relatedTarget.relative(e.item.index)) || 0
        if (newIndex !== carouselPreviewIndex) {
            linkIndex(newIndex)
        }
    })

    // обработчик наведения мыши на маленькое изображение
    jqCarouselThumbnails.find('.item').hover(function () {
        const jqHoveredItem = $(this)

        if (!jqHoveredItem.hasClass('current')) {
            jqHoveredItem.closest('.owl-carousel').find('.item').removeClass('current')
            jqHoveredItem.addClass('current')

            const index = $(this).data('index')
            if (carouselPreviewIndex !== index) {
                jqCarouselPreview.trigger('to.owl.carousel', [index, 300])
            }
        }
    }, () => {
    })

    // выбор первого изображения галереи
    linkIndex(0)

    /**
     * Creates photoswipe album.
     * @param {number} index
     */
    function createPhotoswipe(index) {
        const items = jqCarouselPreview.find('.owl-item:not(.cloned) .item').map(function (n, preview) {
            const jsPreview = $(preview)
            return {
                src: jsPreview.data('src'),
                width: jsPreview.data('width'),
                height: jsPreview.data('height'),
                alt: jsPreview.data('title')
            }
        }).get()

        const gallery = new PhotoSwipe({
            dataSource: items,
            index,
            showHideAnimationType: 'none',
            bgOpacity: 1
        })
        gallery.zoomTo(1)

        // fullscreen icon
        const fullscreenSVG = '<svg aria-hidden="true" class="pswp__icn" viewBox="0 0 32 32" width="32" height="32"><use class="pswp__icn-shadow" xlink:href="#pswp__icn-fullscreen-exit"/><use class="pswp__icn-shadow" xlink:href="#pswp__icn-fullscreen-request"/><path d="M8 8v6.047h2.834v-3.213h3.213V8h-3.213zm9.953 0v2.834h3.213v3.213H24V8h-2.834zM8 17.953V24h6.047v-2.834h-3.213v-3.213zm13.166 0v3.213h-3.213V24H24v-6.047z" id="pswp__icn-fullscreen-request"/><path d="M11.213 8v3.213H8v2.834h6.047V8zm6.74 0v6.047H24v-2.834h-3.213V8zM8 17.953v2.834h3.213V24h2.834v-6.047h-2.834zm9.953 0V24h2.834v-3.213H24v-2.834h-3.213z" id="pswp__icn-fullscreen-exit" style="display:none"/></svg>'
        gallery.on('uiRegister', () => {
            if (!fullscreen.isEnabled) {
                return
            }

            gallery.ui.registerElement({
                name: 'fullscreen-button',
                title: 'Toggle fullscreen',
                order: 9,
                isButton: true,
                html: fullscreenSVG,
                onInit: () => {
                    gallery.on('close', () => {
                        if (fullscreen.isFullscreen) {
                            fullscreen.exit()
                                .catch(console.error)
                        }
                    })
                },
                onClick: () => {
                    fullscreen.request()
                        .catch(console.error)
                }
            })
        })

        // caption
        gallery.on('uiRegister', () => {
            gallery.ui.registerElement({
                name: 'custom-caption',
                html: 'Caption text',
                order: 9,
                isButton: false,
                appendTo: 'root',
                onInit: (el) => {
                    gallery.on('change', () => {
                        el.innerHTML = gallery.currSlide.data.alt || ''
                    })
                }
            })
        })

        gallery.init()
    }

    // Обработчики открытия Photoswipe
    jqCarouselPreview.find('.item').on('click', function () {
        createPhotoswipe($(this).data('index'))
    })

    jqCarouselThumbnails.find('.item').on('click', function () {
        createPhotoswipe($(this).data('index'))
    })
})
